/* eslint-disable */
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from '@material-ui/core/ListItemText';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import profileImage from "assets/img/default-avatar.png";
import commonStyles from "assets/jss/common/common";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { isLogin, logout } from "routes/Auth/Auth";
import SearchBox from 'components/Header/SearchBox'
import { CAPITAL_APP_URL } from 'config';


const styles = (theme) => ({
  ...commonStyles,
  ...headerLinksStyle(theme),
})

const getProfileImage = (profile) => {
  if (!profile) return profileImage;
  let url = profile.image_url;
  return url ? profile.image_url : profileImage;
};
function HeaderLinks({ ...props }) {
  const { classes, dropdownHoverColor } = props;
  const [portfolioConsOpen, setPortfolioConsOpen] = useState(false)

  const isActive = (key) => {
    let routeName = props.current_url;
    if (routeName && routeName.indexOf(key) > -1) {
      return true
    }
    return false
  }
  const history = useHistory()
  const getListItem = (label, url, divider = true) => {

    return (<React.Fragment>
      <ListItem onClick={() => { props.hideDrawer(); history.push(url) }} button>
        <ListItemText primary={label} />
      </ListItem>
      {divider && <Divider />}
    </React.Fragment>)
  }
  return (
    <List >
      {getListItem('Home', '/')}

      {getListItem('About Us', '/?scrollTo=about_us')}
      {getListItem('Contact', '/?scrollTo=contactus')}


      {/* {getListItem('Thematic Investing', '/invest/thematic')}
      {getListItem('Factor Analysis', '/invest/factor')}

      {getListItem('Company Deep Dive', '/investment/company/22784')}
      {getListItem('My Watchlists', '/watchlist/index')} */}

      {/* {getListItem('Who We Are', '/whoweare/index')} */}
    </List >
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};

export default withStyles(styles)(HeaderLinks);
